:root {
	--purcent: 0;
	--gauge-red: #e84c3d;
	--gauge-green: #1eaa59;
	--gauge-yellow: #f1c40f;
}

.gauge-wrapper {
	display: inline-block;
	width: auto;
	margin: 0 auto;
	padding: 20px 15px 15px;
}

.gauge {
	background: #e7e7e7;
	box-shadow: 0 0px 6px 2px rgba(0, 0, 0, 0.5);
	width: 200px;
	height: 100px;
	border-radius: 100px 100px 0 0 !important;
	position: relative;
	overflow: hidden;
}

.gauge-center {
	content: '';
	width: 60%;
	height: 60%;
	background: #15222e;
	border-radius: 100px 100px 0 0 !important;
	position: absolute;
	right: 21%;
	bottom: 0;
	color: #fff;
	z-index: 10;
}

.gauge-center .label,
.gauge-center .number {
	display: block;
	width: 100%;
	text-align: center;
	border: 0 !important;
}

.gauge-center .label {
	font-size: 0.75em;
	opacity: 0.6;
	margin: 1.1em 0 0.3em 0;
}

.gauge-center .number {
	font-size: 1.2em;
}

.needle {
	width: 80px;
	height: 7px;
	background: #15222e;
	border-bottom-left-radius: 100% !important;
	border-bottom-right-radius: 5px !important;
	border-top-left-radius: 100% !important;
	border-top-right-radius: 5px !important;
	position: absolute;
	bottom: -2px;
	left: 20px;
	transform-origin: 100% 4px;
	transform: rotate(0deg);
	box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
	display: none;
	z-index: 9;
}

.three.risk .needle {
	animation: threespeed 2s 1 both;
	animation-delay: 1s;
	display: block;
}

.slice-colors {
	height: 100%;
}

.slice-colors .st {
	position: absolute;
	bottom: 0;
	width: 0;
	height: 0;
	border: 50px solid transparent;
}

.three .slice-colors .st.slice-item:nth-child(1) {
	border-top: 40px var(--gauge-yellow) solid;
	border-right: 60px var(--gauge-yellow) solid;
	border-left: 40px var(--gauge-red) solid;
	border-bottom: 80px var(--gauge-red) solid;
	background-color: var(--gauge-red);
}

.three .slice-colors .st.slice-item:nth-child(3) {
	left: 50%;
	border-bottom: 50px var(--gauge-green) solid;
	border-right: 60px var(--gauge-green) solid;
	border-left: 40px var(--gauge-yellow) solid;
	border-top: 90px var(--gauge-yellow) solid;
	background-color: var(--gauge-yellow);
}

@-webkit-keyframes threespeed {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(calc((var(--purcent) * 180deg) / 100));
	}
}